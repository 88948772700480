import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { meshGatewayClient } from 'src/apollo/client';
import { SortDirectionEnum } from 'src/graphql-types/globalRankingTypes';
import { TD_GenerateRankList } from 'src/graphql-types/TD_GenerateRankList';
import { TD_GenerateRankLists } from 'src/graphql-types/TD_GenerateRankLists';
import { TD_PublishRankLists } from 'src/graphql-types/TD_PublishRankLists';
import { TD_RankListJob } from 'src/graphql-types/TD_RankListJob';
import {
  TD_RankListRunGroups,
  TD_RankListRunGroups_td_rankListRunGroups_items,
} from 'src/graphql-types/TD_RankListRunGroups';
import { useOrgLevel } from 'src/utils/auth';

import Button from '../button/button';
import CustomDialog from '../custom-dialog/custom-dialog';
import { withNotifications } from '../notification-container/notification-container';
import PageHeader from '../page-header/page-header';
import { transformSortDirection, useControlledQuery } from '../table-controls/table-controls';
import { generateRunGroupId, GenerateRunGroupIdProps } from '../usta-rankings/helpers';
import { PageMaxWidth } from '../util-components/util-components';
import {
  TD_GENERATE_RANK_LIST,
  TD_GENERATE_RANK_LISTS,
  TD_PUBLISH_RANK_LISTS,
  TD_RANK_LIST_RUN_GROUPS,
} from './queries';
import TD_GET_RANK_LIST_JOBS from './queries/getRankListJobs';
import BatchRankingRunDialog from './ranking-run-groups/batch-ranking-run-dialog';
import PublishLatestRunDialog from './ranking-run-groups/publish-latest-run-dialog';
import RankingRunGroupsPanel from './ranking-run-groups/ranking-run-groups';
import NewRankingRunDialog from './ranking-run/new-ranking-run-dialog';
import getGenerateRankListInput from './utils/getGenerateRankListInput';
import getGenerateRankListsInput from './utils/getGenerateRankListsInput';
import getRanklistFilters from './utils/getRankListFilters';

const RankingGroups = () => {
  const { t } = useTranslation();
  const { isNational } = useOrgLevel();
  const [batchRunDialogOpen, setBatchRunDialogOpen] = useState(false);
  const [newRankingListDialogOpen, setNewRankingListDialogOpen] = useState(false);
  const [publishRunDialogOpen, setPublishRunDialogOpen] = useState(false);
  const [selectedRankListGroups, setSelectedRankListGroups] = useState<
    TD_RankListRunGroups_td_rankListRunGroups_items[]
  >([]);
  const [generateListError, setGenerateListError] = useState<string | undefined>();

  const [generateRankLists] = useMutation<TD_GenerateRankLists>(TD_GENERATE_RANK_LISTS, {
    client: meshGatewayClient,
    onError: (error) => {
      toast.error(JSON.stringify(error));
    },
  });
  const [generateRankList, { loading: generatingList }] = useMutation<TD_GenerateRankList>(TD_GENERATE_RANK_LIST, {
    client: meshGatewayClient,
  });
  const [publishRankLists, { loading: publishingRun }] = useMutation<TD_PublishRankLists>(TD_PUBLISH_RANK_LISTS, {
    client: meshGatewayClient,
  });
  const [getRankListJobs, { stopPolling, loading: generatingRankLists }] = useLazyQuery<TD_RankListJob>(
    TD_GET_RANK_LIST_JOBS,
    {
      pollInterval: 5000,
      client: meshGatewayClient,
      onCompleted: (getRankListJobs) => {
        const jobs = getRankListJobs?.td_rankListJobs;

        if (jobs) {
          const allJobsComplete = jobs.every((job) => job?.jobComplete);
          if (allJobsComplete) {
            setBatchRunDialogOpen(false);
            stopPolling();
            handleRefetchGroups();
            handleSetSelectedRankListGroups([]);
          }
          jobs.forEach((job) => {
            if (job?.jobErrors?.length) {
              toast.error(JSON.stringify(job?.jobErrors));
            }
          });
        }
      },
    },
  );

  const [filter, setFilter] = useState({
    category: '',
    division: '',
    format: '',
    listType: '',
    section: '',
    publishStatus: '',
    gender: '',
  });

  const handleSetBatchRunDialogOpen = useCallback((open) => {
    setBatchRunDialogOpen(open);
  }, []);
  const handleSetPublishRunDialogOpen = useCallback((open) => {
    setPublishRunDialogOpen(open);
  }, []);
  const handleSetNewRankingListDialogOpen = useCallback((open) => {
    setNewRankingListDialogOpen(open);
    setGenerateListError(undefined);
  }, []);
  const handleSetSelectedRankListGroups = useCallback((groups) => {
    setSelectedRankListGroups(groups);
  }, []);

  // Construct filters, pagination and sorting for data table controlled query
  const controlledRankListRunGroupsQueryOptions = useMemo(
    () => ({
      client: meshGatewayClient,
      getTotalItems: (d) => d.td_rankListRunGroups?.totalItems,
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only' as any,
      transformVariables: (v: any) => {
        const queryFilters = {
          filter: getRanklistFilters({
            ...filter,
          }),
        };
        const { sorts, limit, offset } = v;

        if (!sorts)
          return {
            pageArgs: { limit, skip: offset },
            ...queryFilters,
            sort: {
              field: 'latestListCreatedAt',
              direction: SortDirectionEnum.DESC,
            },
          };

        const [{ sortDirection, property }] = sorts;
        return {
          pageArgs: { limit, skip: offset },
          ...queryFilters,
          sort: {
            field: property,
            direction: transformSortDirection(sortDirection),
          },
        };
      },
    }),
    [filter],
  );

  // Fetch run groups and schedules
  const {
    loading,
    data,
    refetch: refetchGroups,
    controlProps,
    error,
  } = useControlledQuery<TD_RankListRunGroups>(TD_RANK_LIST_RUN_GROUPS, controlledRankListRunGroupsQueryOptions);

  useEffect(() => {
    if (error) {
      toast.error(t('rank list groups error'));
    }
  }, [error, t]);

  const runGroups = useMemo(() => data?.td_rankListRunGroups?.items ?? [], [data]);

  const handleRefetchGroups = useCallback(() => {
    refetchGroups();
  }, [refetchGroups]);

  // Append generated id and schedules to run groups
  const runGroupsWithSchedules: Partial<TD_RankListRunGroups_td_rankListRunGroups_items>[] = useMemo(() => {
    return runGroups?.map((group) => ({
      ...group,
      id: generateRunGroupId(group as unknown as GenerateRunGroupIdProps),
    }));
  }, [runGroups]);

  const handleCreateBatchRuns = useCallback(
    async (data) => {
      const input = getGenerateRankListsInput(data, selectedRankListGroups);
      const generateRankListResponse = await generateRankLists({
        variables: {
          input,
        },
      });
      const jobIds = generateRankListResponse?.data?.td_generateRankLists?.map((job) => job?.id);
      if (jobIds?.length) {
        getRankListJobs({
          variables: {
            filter: {
              id: {
                in: jobIds,
              },
            },
          },
        });
      }
    },
    [generateRankLists, getRankListJobs, selectedRankListGroups],
  );

  const handleCreateIndividualRun = useCallback(
    async (data) => {
      const input = getGenerateRankListInput(data);
      const response = await generateRankList({
        variables: {
          input,
        },
      });
      if (response?.data?.td_generateRankList?.__typename === 'td_GenerateRankListSuccess') {
        setNewRankingListDialogOpen(false);
        handleRefetchGroups();
      }
      if (response?.data?.td_generateRankList?.__typename === 'td_GenerateRankListErrors') {
        toast.error(t('generate ranklist error'));
        setGenerateListError(JSON.stringify(response?.data?.td_generateRankList?.errors));
      }
    },
    [generateRankList, handleRefetchGroups, t],
  );

  const handlePublishLatestRun = useCallback(async () => {
    const latestRankListIds = selectedRankListGroups?.map((listGroup) => listGroup.latestListId);
    const response = await publishRankLists({
      variables: {
        ids: latestRankListIds,
      },
    });
    if (response?.data?.td_publishRankLists) {
      handleSetPublishRunDialogOpen(false);
      refetchGroups();
    }
  }, [handleSetPublishRunDialogOpen, publishRankLists, refetchGroups, selectedRankListGroups]);

  const handleNavigateToRankingRun = useCallback((row) => {
    const {
      listType,
      playerType,
      ageRestriction,
      gender,
      genderModifier,
      matchFormat,
      matchFormatType,
      playerLevel,
      familyCategory,
      latestListMadeVisible,
      region,
      divisionType,
      rankListJobIds,
    } = row;
    const rankListJobParams = rankListJobIds?.length ? rankListJobIds.map((id) => `ranklistJobs=${id}`).join('&') : '';
    const rankListQueryParams = `?listType=${listType}&playerType=${playerType}&ageRestriction=${ageRestriction}&gender=${gender}&genderModifier=${genderModifier}&matchFormat=${matchFormat}&matchFormatType=${matchFormatType}&playerLevel=${playerLevel}&divisionType=${divisionType}&latestListMadeVisible=${latestListMadeVisible}&familyCategory=${familyCategory}&region=${region}`;
    const url = `/rankings/group${rankListQueryParams}&${rankListJobParams}`;
    navigate(url);
  }, []);

  return (
    <PageMaxWidth>
      <Grid container direction="row" justifyContent="space-between">
        <PageHeader title={t('rankings')} />
        <Button
          spacing={{ margins: { auto: 'left' } }}
          onClick={() => setNewRankingListDialogOpen(true)}
          hide={!isNational}
        >
          {t('new ranking list')}
        </Button>
      </Grid>
      <RankingRunGroupsPanel
        setBatchRunDialogOpen={handleSetBatchRunDialogOpen}
        setPublishRunDialogOpen={handleSetPublishRunDialogOpen}
        setSelectedRankListGroups={handleSetSelectedRankListGroups}
        runGroupsWithSchedules={runGroupsWithSchedules}
        handleNavigateToRankingRun={handleNavigateToRankingRun}
        filter={filter}
        setFilter={setFilter}
        controlProps={controlProps}
        loading={loading}
      />
      <CustomDialog
        title={t('add new run')}
        open={batchRunDialogOpen}
        hideX
        onClose={() => setBatchRunDialogOpen(false)}
        content={
          <BatchRankingRunDialog
            selectedGroups={selectedRankListGroups}
            onCancel={() => handleSetBatchRunDialogOpen(false)}
            onSubmit={handleCreateBatchRuns}
            generating={generatingRankLists}
          />
        }
      />
      <CustomDialog
        title={t('add new ranking list')}
        open={newRankingListDialogOpen}
        hideX
        visibleOverflow
        onClose={() => setBatchRunDialogOpen(false)}
        content={
          <NewRankingRunDialog
            onCancel={() => handleSetNewRankingListDialogOpen(false)}
            onSubmit={handleCreateIndividualRun}
            loading={generatingList}
            error={generateListError}
          />
        }
      />
      <CustomDialog
        title={t('publish latest runs')}
        open={publishRunDialogOpen}
        hideX
        visibleOverflow
        onClose={() => setBatchRunDialogOpen(false)}
        content={
          <PublishLatestRunDialog
            onCancel={() => handleSetPublishRunDialogOpen(false)}
            onSubmit={handlePublishLatestRun}
            loading={publishingRun}
            numberOfSelected={selectedRankListGroups?.length}
          />
        }
      />
    </PageMaxWidth>
  );
};

export default withNotifications(RankingGroups);
