import React from 'react';

import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TD_RankListRunGroups_td_rankListRunGroups_items } from 'src/graphql-types/TD_RankListRunGroups';
import { getOneDayBefore, getOneYearAgo } from 'src/utils/helper/dates';

import Button from '../../button/button';
import { CustomGrid } from '../../custom-grid/custom-grid';
import DatePicker from '../../date-picker/date-picker';
import Icon from '../../icon/icon';
import { Body } from '../../typography/typography';
import useDateRange from '../utils/useDateRange';
import * as styles from './batch-ranking-run-dialog.module.less';

interface BatchRankingRunDialogProps {
  selectedGroups: TD_RankListRunGroups_td_rankListRunGroups_items[];
  onCancel: () => void;
  onSubmit: (data: any) => void;
  generating: boolean;
}

const BatchRankingRunDialog: React.FC<BatchRankingRunDialogProps> = ({
  selectedGroups,
  onCancel,
  onSubmit,
  generating,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm();

  // By default, set the start and end date to construct a list across the last 12 months
  useDateRange(setValue, getOneYearAgo, getOneDayBefore);

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="batch-ranking-run-form">
      <CustomGrid container direction="row" spacing={{ margins: { lg: 'bottom' } }}>
        <CustomGrid item xs spacing={{ margins: { sm: 'right' } }}>
          <Body size="md" spacing={{ margins: { xs: 'vertical' } }}>
            {t('run period start')}
          </Body>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => <DatePicker selected={field.value} onChange={(date) => field.onChange(date)} />}
          />
        </CustomGrid>
        <CustomGrid item xs spacing={{ margins: { sm: 'left' } }}>
          <Body size="md" spacing={{ margins: { xs: 'vertical' } }}>
            {t('run period end')}
          </Body>
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => <DatePicker selected={field.value} onChange={(date) => field.onChange(date)} />}
          />
        </CustomGrid>
      </CustomGrid>
      <Body size="lg">
        {t('batch run explanation', {
          amount: selectedGroups?.length,
        })}
      </Body>
      <CustomGrid container className={styles.annotationContainer} spacing={{ margins: { lg: 'top' } }}>
        <Grid item xs={1}>
          <Icon name="md-clock" />
        </Grid>
        <Grid item xs={10}>
          <Body size="lg" bold>
            {t('creating multiple runs')}
          </Body>
          <Body size="lg" spacing={{ margins: { xs: 'top' } }}>
            {t('cannot generate runs')}
          </Body>
        </Grid>
      </CustomGrid>
      <CustomGrid style={{ display: 'flex', justifyContent: 'flex-end' }} spacing={{ margins: { lg: 'top' } }}>
        <Button onClick={onCancel} type="button" level="tertiary">
          {t('cancel')}
        </Button>
        <Button type="submit" spacing={{ margins: { sm: 'left' } }} loading={generating}>
          {t('create new runs')}
        </Button>
      </CustomGrid>
    </form>
  );
};

export default BatchRankingRunDialog;
